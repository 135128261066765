






















import CultureAction from '@/components/culture/action/Action.vue'
import CultureTeam from '@/components/culture/team/Team.vue'

import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'VCulture',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */
    }
  },
  components: {
    CultureAction,
    CultureTeam,
  },
  setup() {
    return {
      ...useGetters(['content', 'template']),
    }
  },
})
































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import CultureTeam from '@/components/culture/team/Team.vue'

export default defineComponent({
  name: 'VOrganisation',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */

      /* eslint-enable */
    }
  },
  components: {
    CultureTeam,
  },
  setup() {
    return {
      ...useGetters(['content', 'template']),
    }
  },
})






















import { defineComponent } from '@vue/composition-api'
import { ContactItem } from '@/inc/types'

export default defineComponent({
  name: 'contact-item',
  props: {
    content: {
      type: Object as () => ContactItem,
      default: {} as ContactItem,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
})

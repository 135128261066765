





















































































































































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import Slidy, { TransitionInfos } from 'epic-slidy'
import { Slide } from '@/inc/types'

import { gsap } from 'gsap'
import { SplitText } from '@/inc/vendor/gsap-extra/SplitText'
gsap.registerPlugin(SplitText)

export default defineComponent({
  name: 'action-slidy',
  props: {
    slides: {
      type: Array as () => Slide[],
      default: [],
    },
  },

  setup() {
    let slider: Slidy
    const link = ref<null | HTMLElement>(null)
    const list = ref<null | HTMLElement>(null)
    const picture = ref<null | HTMLElement>(null)
    const progress = ref<null | HTMLElement>(null)
    const progressBar = ref<null | HTMLElement>(null)
    const slide = ref<null | Array<HTMLElement>>(null)
    const title = ref<null | HTMLElement>(null)
    const text = ref<null | HTMLElement>(null)

    onMounted(() => {
      const splitTitle = new SplitText(title.value, {
        type: 'words',
      })
      const splitText = new SplitText(text.value, {
        type: 'lines',
      })

      const updateProgress = () => {
        if (progressBar.value) {
          reset()
          gsap.to(progressBar.value, 10, {
            backgroundPosition: '-100%',
            ease: 'linear',
          })
        }
      }

      const reset = () => {
        if (!progressBar.value) {
          return
        }
        gsap.killTweensOf(progressBar.value)
        gsap.set(progressBar.value, { clearProps: 'all' })
      }

      const transition = (
        currentSlide,
        newSlide,
        { currentIndex, newIndex }: TransitionInfos
      ) => {
        updateProgress()

        const tl = gsap.timeline()
        const current = {
          /* eslint-disable */
          pictures: picture?.value![currentIndex].$refs.picture,
          title: splitTitle.elements[currentIndex],
          text: splitText.elements[currentIndex],
          link: link?.value![currentIndex].$el,
          /* eslint-enable */
        }

        const next = {
          /* eslint-disable */
          pictures: picture?.value![newIndex].$refs.picture,
          title: splitTitle.elements[newIndex],
          text: splitText.elements[newIndex],
          link: link?.value![newIndex].$el,
          /* eslint-enable */
        }

        tl.add('transition')
          .set(newSlide, {
            zIndex: 3,
          })
          .set(next.pictures, {
            scale: 1.2,
            opacity: 0,
          })
          .set(next.title.children, {
            opacity: 0,
            y: 30,
          })
          .set(next.text.children, {
            opacity: 0,
            y: 10,
          })
          .set(next.link, {
            opacity: 0,
            y: 10,
          })
          .to(
            current.pictures,
            {
              duration: 0.2,
              opacity: 0,
              stagger: 0.05,
              ease: 'power4.inOut',
            },
            'transition'
          )
          // .to(
          //   currentSlide,
          //   {
          //     duration: 0,
          //     opacity: 0,
          //   },
          //   'transition+0.5'
          // )
          .to(
            current.title.children,
            {
              duration: 0.2,
              opacity: 0,
              y: -30,
              stagger: 0.05,
            },
            'transition+=0.1'
          )
          .to(
            current.text.children,
            {
              duration: 0.2,
              opacity: 0,
              y: -10,
              stagger: 0.02,
            },
            'transition+=0.1'
          )
          .to(
            current.link,
            {
              duration: 0.2,
              opacity: 0,
              y: -10,
            },
            'transition+=0.4'
          )
          // News Slide Management
          .to(
            newSlide,
            {
              duration: 0,
              opacity: 1,
            },
            'transition+=0.1'
          )
          .to(
            next.title.children,
            {
              duration: 0.2,
              opacity: 1,
              y: 0,
              stagger: 0.05,
            },
            'transition+=0.1'
          )
          .to(
            next.text.children,
            {
              duration: 0.2,
              opacity: 1,
              y: 0,
              stagger: 0.02,
            },
            'transition+=0.3'
          )
          .to(
            next.link,
            {
              duration: 0.2,
              opacity: 1,
              y: 0,
            },
            'transition+=0.4'
          )
          .to(
            next.pictures,
            {
              duration: 0.2,
              opacity: 1,
              stagger: 0.05,
              scale: 1,
              ease: 'power4',
            },
            'transition'
          )

        return tl.then()
      }

      slider = new Slidy(list.value as HTMLElement, {
        click: true,
        pagination: false,
        pause: false,
        nav: 'number',
        auto: true,
        loop: true,
        interval: 10000,
        transition,
      })
      slider.init()
      updateProgress()
    })

    onUnmounted(() => {
      slider.destroy()
    })

    return {
      link,
      list,
      picture,
      progress,
      progressBar,
      slide,
      title,
      text,
    }
  },
})

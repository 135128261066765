





































































import { defineComponent } from '@vue/composition-api'
import { MembersItem } from '@/inc/types'

export default defineComponent({
  name: 'members-item',
  props: {
    content: {
      type: Object as () => MembersItem,
      default: {} as MembersItem,
    },
    modifier: {
      type: String,
      default: '',
    },
  },
})

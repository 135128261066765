

























































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import Slidy from 'epic-slidy'
import { SliderProfile } from '@/inc/types'

export default defineComponent({
  name: 'profiles-slidy',
  props: {
    content: {
      type: Object as () => SliderProfile,
      default: [],
    },
  },

  setup() {
    let slider: Slidy
    const list = ref<null | HTMLElement>(null)
    const picture = ref<null | HTMLElement>(null)
    const pictures = ref<null | HTMLElement>(null)
    const progress = ref<null | HTMLElement>(null)

    onMounted(() => {
      const transition = (currentSlide: HTMLElement, newSlide: HTMLElement) => {
        // REVIEW: returns gsap promise directly
        const tl = gsap.timeline({
          onComplete: () => {
            currentSlide.style.zIndex = '1'
            newSlide.style.zIndex = '3'
          },
        })

        const current = {
          /* eslint-disable */
          content: currentSlide.querySelector('.profiles-slider__item__misc'),
          title: currentSlide.querySelector(
            '.profiles-slider__item__misc__title'
          ),
          function: currentSlide.querySelector(
            '.profiles-slider__item__misc__function'
          ),
          quote: currentSlide.querySelector('.profiles-slider__item__content'),
          quoteOuter: currentSlide.querySelector(
            '.profiles-slider__item__content-outer'
          ),
          pictures: currentSlide.querySelectorAll('.picture-inner'),
          /* eslint-enable */
        }

        const next = {
          /* eslint-disable */
          content: newSlide.querySelector('.profiles-slider__item__misc'),
          title: newSlide.querySelector('.profiles-slider__item__misc__title'),
          function: newSlide.querySelector(
            '.profiles-slider__item__misc__function'
          ),
          quote: newSlide.querySelector('.profiles-slider__item__content'),
          quoteOuter: newSlide.querySelector(
            '.profiles-slider__item__content-outer'
          ),
          pictures: newSlide.querySelectorAll('.picture-inner'),
          /* eslint-enable */
        }

        return tl
          .add('transition')
          .set(next.pictures, {
            x: '100%',
            onComplete() {
              // newSlide.style.zIndex = '4'
              newSlide.style.opacity = '1'
            },
          })
          .set(next.quote, {
            y: 100,
            opacity: 0,
          })
          .set([next.title, next.function], {
            opacity: 0,
            y: 50,
            stagger: 0.1,
          })
          .set(next.content, {
            opacity: 1,
          })
          .to(
            current.quote,
            {
              duration: 1,
              opacity: 0,
              y: -100,
              ease: 'power4.inOut',
            },
            'transition'
          )
          .to(
            next.quote,
            {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'power4.inOut',
            },
            'transition'
          )
          .to(
            current.quoteOuter,
            {
              duration: 1,
              opacity: 0,
              ease: 'linear',
            },
            'transition'
          )
          .to(
            next.quoteOuter,
            {
              duration: 0.2,
              opacity: 1,
              ease: 'linear',
            },
            '< -=1.7'
          )
          .to(
            [current.title, current.function],
            {
              duration: 1,
              opacity: 0,
              y: -50,
              stagger: 0.2,
              ease: 'power4.inOut',
            },
            'transition'
          )
          .to(
            current.content,
            {
              duration: 1,
              opacity: 0,
              ease: 'power4.inOut',
            },
            'transition+=0.3'
          )
          .to(
            [next.title, next.function],
            {
              duration: 1,
              opacity: 1,
              y: 0,
              stagger: 0.1,
              ease: 'power4.out',
            },
            'transition'
          )
          .to(
            current.pictures,
            {
              duration: 1,
              stagger: 0.1,
              x: '-100%',
              ease: 'power4.inOut',
            },
            'transition+=0.1'
          )
          .to(
            next.pictures,
            {
              duration: 1,
              opacity: 1,
              scale: 1,
              stagger: 0.1,
              x: 0,
              ease: 'power4.inOut',
            },
            'transition+=0.1'
          )

          .then()
      }

      slider = new Slidy(list.value as HTMLElement, {
        click: false,
        auto: false,
        loop: true,
        // controls: true,
        transition,
      })
      slider.init()
    })

    const resize = () => {
      slider.destroy()
      slider.init()
      console.log('coucou')
    }

    const next = () => {
      slider.slideNext('controls')
    }

    const prev = () => {
      slider.slidePrev('controls')
    }

    onUnmounted(() => {
      slider.destroy()
    })

    return {
      list,
      next,
      picture,
      pictures,
      prev,
      progress,
      resize,
    }
  },
})

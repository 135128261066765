






















































































































































import { defineComponent } from '@vue/composition-api'
import { CultureTeam } from '@/inc/types'

export default defineComponent({
  name: 'culture-team',
  props: {
    content: {
      type: Object as () => CultureTeam,
      default: {} as CultureTeam,
    },
  },
  components: {},
})






















































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import ProfilesSlider from '@/components/profiles/Slider.vue'

export default defineComponent({
  name: 'VJobs',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */
      /* eslint-enable */
    }
  },
  components: { ProfilesSlider },
  setup() {
    return {
      ...useGetters(['content', 'template', 'chrome']),
    }
  },
})











































































































































import { defineComponent } from '@vue/composition-api'
import { MembersGrid } from '@/inc/types'
import MembersItem from './Item.vue'

export default defineComponent({
  name: 'members-grid',
  props: {
    content: {
      type: Object as () => MembersGrid,
      default: {} as MembersGrid,
    },
  },
  components: {
    MembersItem,
  },
})

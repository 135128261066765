















import ActionSlidy from './Slidy.vue'

import { defineComponent } from '@vue/composition-api'
import { CultureAction } from '@/inc/types'

export default defineComponent({
  name: 'culture-action',
  props: {
    content: {
      type: Object as () => CultureAction,
      default: {} as CultureAction,
    },
  },
  components: {
    ActionSlidy,
  },
})

















































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'VAdndw',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */
      /* eslint-enable */
    }
  },
  components: {},
  setup() {
    return {
      ...useGetters(['content', 'template', 'chrome']),
    }
  },
})

import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=4dc4847b&scoped=true&"
import script from "./Team.vue?vue&type=script&lang=ts&"
export * from "./Team.vue?vue&type=script&lang=ts&"
import style0 from "./Team.vue?vue&type=style&index=0&id=4dc4847b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc4847b",
  null
  
)

export default component.exports